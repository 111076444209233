import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { DangerouslySetInnerHtml } from 'layout';
import Section from 'common/Section';
import { ITextSectionPropsFront } from '@shared/types';
import classNames from 'classnames';

import './TextSection.scss';

const TextSection: FC<ITextSectionPropsFront> = ({ text, color, isIntro }) =>
  text ? (
    <Section
      name="text"
      color={color}
      classes={classNames({
        'text-section--is-intro': isIntro,
      })}
    >
      <DangerouslySetInnerHtml className="text-section__description" html={text} />
    </Section>
  ) : null;

export default TextSection;

export const query = graphql`
  fragment FragmentTextSection on TTextSection {
    properties {
      text
      color {
        label
      }
      isIntro
    }
    structure
  }
`;

import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'gatsby-theme-husky/src/layout/Layout';
import BodyRenderer from 'gatsby-theme-husky/src/common/BodyRenderer';
import SearchPageContent from 'components/SearchPage/SearchPageContent';
import SearchPageBanner from 'components/SearchPage/SearchPageBanner';
import TextSection from 'components/TextSection';
import InfoBanner from 'components/InfoBanner';
import SignUp from 'components/SignUp';

import { SearchPageTypes } from '@shared/types/umbraco/content/searchPage';

import algoliaSearch from 'algoliasearch/lite';
import InstantSearch from 'react-instantsearch-core/dist/es/widgets/InstantSearch';
import { Configure } from 'react-instantsearch-dom';

const { GATSBY_ALGOLIA_SEARCH_QUERY_INDEX } = process.env;
const { GATSBY_ALGOLIA_SEARCH_KEY } = process.env;
const { GATSBY_ALGOLIA_APP_ID } = process.env;

const algoliaClient = algoliaSearch(GATSBY_ALGOLIA_APP_ID, GATSBY_ALGOLIA_SEARCH_KEY);

const elements = {
  'SearchPage Content': ({ properties }, keyId) => (
    <SearchPageContent key={keyId} {...properties} />
  ),
  'Signup Placeholder': ({ properties }, keyId) => <SignUp key={keyId} {...properties} />,
  'Text Section': ({ properties }, keyId) => <TextSection key={keyId} {...properties} />,
  'Info Banner': ({ properties }, keyId) => <InfoBanner key={keyId} {...properties} />,
};

const SearchPage: FC<SearchPageTypes.IProperties> = ({
  pageContext: { breadCrumbs, link, searchUrl },
  data: {
    page: {
      nodes: [
        {
          banner,
          defaultCompositions,
          body,
          seoMetaKeywords,
          seoMetaTitle,
          seoMetaTitleTemplate,
          seoMetaDescription,
          seoExternalHreflangs,
          seoNoIndex,
          alternateUrls,
          ogImageUrl,
        },
      ],
    },
  },
}) => {
  const { purchaseSettings, waiSettings } = defaultCompositions;

  return (
    <Layout
      {...{
        ...defaultCompositions,
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaTitleTemplate,
        seoNoIndex,
        seoMetaDescription,
        seoExternalHreflangs,
        purchaseSettings,
        skipText: waiSettings?.skipText,
        link,
        searchUrl,
        alternateUrls,
        imageUrl: ogImageUrl,
      }}
    >
      <InstantSearch searchClient={algoliaClient} indexName={GATSBY_ALGOLIA_SEARCH_QUERY_INDEX}>
        <Configure
          analytics={false}
          distinct
          filters={`lang:${defaultCompositions.siteSettings.lang}`}
          hitsPerPage={200}
        />
        {banner?.length ? (
          <SearchPageBanner {...{ ...banner[0], breadCrumbs }} searchUrl={searchUrl} />
        ) : null}
        <BodyRenderer
          bodyData={body}
          bodyStructure={elements}
          bodyItemProps={{
            ...{
              singupFormBaner: defaultCompositions.singupFormBaner,
              waiSettings: defaultCompositions.waiSettings,
              siteSettings: defaultCompositions.siteSettings,
              productListingSettings: defaultCompositions.productListingSettings,
            },
          }}
        />
      </InstantSearch>
    </Layout>
  );
};
export const query = graphql`
  query allSearchQuery($link: String = "") {
    page: allSearch(filter: { link: { eq: $link } }) {
      nodes {
        seoMetaTitle
        seoMetaTitleTemplate
        seoMetaDescription
        seoMetaKeywords
        seoNoIndex
        seoExternalHreflangs {
          key
          value
        }
        alternateUrls {
          lang
          url
          path
        }
        ogImageUrl
        defaultCompositions {
          footer {
            ...FragmentFooter
          }
          header {
            ...FragmentHeader
          }
          waiSettings {
            skipText
            ariaPrev
            ariaNext
          }
          productListingSettings {
            showing
          }
          purchaseSettings {
            ...FragmentPurchaseSettings
          }
          siteSettings {
            ...FragmentSiteSettingsComposition
          }
          brandSettings {
            ...FragmentBrandSettingsComposition
          }
          singupFormBaner {
            ...FragmentSignUpBanner
          }
          warning {
            ...FragmentWarning
          }
          signUpPopup {
            ...FragmentSignUpPopup
          }
          signUpFormPopup {
            ...FragmentSignUpFormPopup
          }
        }
        banner {
          ...FragmentSearchBanner
        }
        body {
          ... on TSearchPageContent {
            ...FragmentSearchPageContent
          }
          ... on TSignupPlaceholder {
            ...FragmentSignUp
          }
          ... on TTextSection {
            ...FragmentTextSection
          }
          ... on TInfoBanner {
            ...FragmentInfoBanner
          }
        }
      }
    }
  }
`;

export default SearchPage;
